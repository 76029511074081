<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form" class="form-label-4">
            <a-form-item label="项目">
              <a-select
                class="form-control lg"
                v-decorator="['projectCode']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :filterOption="filterByPY"
                @change="onSelectProject"
              ></a-select>
            </a-form-item>
            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <div class="queryView-actions">
          <a-button type="primary" @click="addRow">新增班组</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal v-model="modal.visible" :title="modal.title" @ok="handleOk">
      <a-form :form="modal.form" v-bind="modalFormLayout">
        <a-form-item label="项目">
          <a-select
            class="form-control lg"
            v-decorator="['projectCode', { rules: [{ required: true, message: '请选择' }] }]"
            placeholder="请选择"
            allowClear
            showSearch
            :options="projectList"
            :filterOption="filterByPY"
          ></a-select>
        </a-form-item>
        <a-form-item label="身份证号">
          <a-input
            v-decorator="[
              'cardId',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
            placeholder="请输入身份证号"
          ></a-input>
        </a-form-item>
        <a-form-item label="名字">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
            placeholder="请输入名字"
          ></a-input>
        </a-form-item>
        <a-form-item label="oa编码">
          <a-input
            v-decorator="[
              'leaderIdOa',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
            placeholder="请输入oa编码"
          ></a-input>
        </a-form-item>
        <a-form-item label="工种">
          <a-input
            v-decorator="[
              'workerType',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]"
            placeholder="请输入工种"
          ></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import { getProjectList, getLeaderList } from '@/service/getData'
export default {
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },
      {
        title: '名称',
        width: '6%',
        align: 'center',
        dataIndex: 'leaderName',
      },
      {
        title: 'oa编码',
        width: '15%',
        align: 'center',
        dataIndex: 'leaderIdOa',
      },
      {
        title: '工种',
        width: '6%',
        align: 'center',
        dataIndex: 'workerType',
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],

      modal: {
        visible: false,
        form: this.$form.createForm(this),
        title: '',
        data: {},
      },

      projectList: [],
      leaderList: [],
    }
  },
  computed: {
    typesMap() {
      return {}
    },
  },
  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/attendance/web/project/leader/list', {
          ...this.getPageParams(_pageNo, _pageSize),
        })
        const { pageSize, pageNo, totalCount, list } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async onSelectProject(projectCode) {
      this.form.setFieldsValue({ leaderId: undefined })
      this.leaderList = projectCode ? await getLeaderList(projectCode) : []
    },
    addRow() {
      this.resetModal()
      this.modal.visible = true
      this.modal.title = '新增'
    },
    handleOk() {
      this.modal.form.validateFields(async (errors, values) => {
        if (!errors) {
          const params = values
          try {
            await this.$axios.post('/attendance/web/project/leader/add', {
              ...params,
            })
            this.$message.success('操作成功!')
            this.modal.visible = false
            this.doQuery()
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
  },
  async mounted() {
    this.projectList = await getProjectList()
  },
}
</script>

<style lang="less" scoped></style>
